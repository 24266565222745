import React from "react";
import { Card } from "../../components/cards/Card";
import { Header } from "../../components/header/Header";
import { ChildWrapper } from "../../components/layouts/ChildWrapper";

export const Services = () => {
  const services = [
    {
      title: "Car Rentals",
      subtitle:
        "Daily rentals, Airport transfers, Inter and Intra state travels.",
      icon: "single-car",
      delay: 100,
      link: "http://app.rideonnigeria.co/booking/Ride",
    },
    // {
    //   title: "Airport Transfers",
    //   subtitle: "We have various model of cars from verified dealers listed.",
    //   icon: "landing-plane",
    //   delay: 200,
    // },
    {
      title: "Experienced Drivers",
      subtitle:
        "Full-time driver hire or book an On-Demand driver for a short period of time.",
      icon: "steering-wheels",
      delay: 300,
      link: "http://app.rideonnigeria.co/booking/Driver",
    },
    // {
    //   title: "Flight Booking",
    //   subtitle:
    //     "Get cheap flight ticket and a reliable airline to any location.",
    //   icon: "plane",
    //   delay: 100,
    // },
    {
      title: "Travel and Tourism",
      subtitle:
        "Get cheap flight tickets and vacation package to any country of your choice.",
      icon: "luggage",
      delay: 200,
      link: "http://app.rideonnigeria.co/booking/Driver/FullTime",
    },
    {
      title: "Escort and Protocol",
      subtitle: "We have various model of cars from verified dealers listed.",
      icon: "protocol",
      delay: 300,
      link: "http://app.rideonnigeria.co/booking/Ride",
    },
    {
      title: "Fleet Management",
      subtitle:
        "Vehicle maintenance, real-time GPS fleet tracking, trip scheduling and driver oversight.",
      icon: "fleet",
      delay: 100,
      link: "http://app.rideonnigeria.co/booking/Ride",
    },
    {
      title: "Logistics Support",
      subtitle: "Simplify and connect your supply chain from end to end.",
      icon: "logistics",
      delay: 200,
      link: "http://app.rideonnigeria.co/booking/Ride",
    },
  ];

  return (
    <div className="relative mb-20 md:mb-40 services">
      <ChildWrapper>
        <div>
          <Header
            preTitle="SERVICES"
            preTitleClass="text-custom-black"
            title={
              <span className="text-custom-black">
                Services <span className="text-secondary">We Offer</span>
              </span>
            }
          />
          <div className="text-base text-custom-black md:text-xl mt-3 md:max-w-3xl">
            We at RideOnNigeria are big on customer saticfaction and as a
            result, we have carefully curated services tailored to meet every of
            you transport needs.
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-10 w-10/12">
            {services.map(({ title, subtitle, icon, delay, link }, i) => {
              return (
                <Card
                  svg={icon}
                  title={title}
                  subtext={subtitle}
                  hasButton={true}
                  key={i}
                  dataAos="zoom-in"
                  AosDelay={delay}
                  link={link}
                />
              );
            })}
          </div>
        </div>
      </ChildWrapper>
    </div>
  );
};
