import React from "react";
import "./textinput.css";

export const TextArea = ({
  title = "",
  type = "",
  value = "",
  error = "",
  rows,
  ...inputProps
}) => {
  return (
    <div>
      <div className="relative input">
        <textarea
          {...inputProps}
          className={`input__field ${
            value && value.length > 0 ? "--filled" : ""
          }
                ${error && "--error"}`}
          rows={rows ? rows : 4}
        />
      </div>
      {error && (
        <div className="text-red pl-2 mt- text-sm text-left">{error}</div>
      )}
    </div>
  );
};
