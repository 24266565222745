import React from "react";
import { Button } from "../../components/button/Button";
// import { Header } from "../../components/header/Header";
import { ChildWrapper } from "../../components/layouts/ChildWrapper";

export const AboutUs = ({ onLearnMore }) => {
  return (
    <div className="py-20 md:my-20">
      <ChildWrapper className="h-full rounded-lg text-custom-black">
        <div className="w-full flex flex-wrap gap-y-6 h-full items-center">
          <div
            className="w-full md:w-5/12 md:min-w-[500px] text-left"
            data-aos="fade-up"
          >
            {/* <img
              src="./assets/images/star.svg"
              alt="Ford SUV"
              className="object-auto max-h-[50px]"
            /> */}
            <div className="max-w-xl calibre-sbold text-3xl md:text-5xl font-medium mt-6">
              RideOnNigeria is{" "}
              <span className="calibre-bold">Nigeria's number one</span> car
              rental and driver hire company.
            </div>
            <div className="text-base md:text-xl mt-3 md:max-w-md">
              RideOnNigeria is Nigeria's number one transport company. We are a
              dynamic organisation rendering unrivaled services covering Car
              Rental, Driver Hire, Travel and Tourism, Fleet Management and
              Logistics Support.
            </div>
            <Button
              type="button"
              variation="black"
              title="Learn more"
              onClick={onLearnMore}
              className="mt-6 !py-2 !px-10 md:ml-0 !text-lg"
              hasIcon={false}
            />
          </div>

          <div className="w-full md:w-7/12 md:min-w-[500px] flex justify-end lg:pl-10">
            <img
              src="./assets/images/lexus.jpeg"
              alt="Ford SUV"
              className="object-auto max-h-[600px] w-full rounded-md"
              data-aos="fade-up"
              data-aos-delay={500}
            />
          </div>
        </div>
      </ChildWrapper>
    </div>
  );
};
