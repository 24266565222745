import React from "react";
import { Icon } from "../Icon/Icon";
import ClipLoader from "react-spinners/ClipLoader";

export const Button = ({
  type,
  variation,
  title,
  onClick,
  className,
  hasIcon = true,
  loading,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`text-base md:text-lg lg:text-xl py-2 px-6 border-2 text-white font-bold
      ${variation === "primary" && "border-secondary bg-secondary text-white"}
      ${
        variation === "secondary" &&
        "border-secondary bg-transparent text-secondary"
      }
      ${
        variation === "white-outline" &&
        "border-white bg-transparent text-white"
      }
      ${variation === "white" && "border-white bg-white text-custom-black"}
      ${variation === "green" && "border-green bg-green text-white"}
      ${
        variation === "black" &&
        "border-custom-black bg-transparent text-custom-black"
      }
      ${
        variation === "black-fill" &&
        "border-custom-black bg-custom-black text-white"
      }
      ${className}
      hover:scale-90 transition-all
      flex items-center gap-4 rounded-md calibre w-fit
      `}
    >
      {hasIcon && <Icon svg="phone-icon" width={18} height={18} />}
      {loading ? (
        <ClipLoader
          color={"white"}
          loading={true}
          // size={150}
          aria-label="Loading Spinner"
        />
      ) : (
        title
      )}
    </button>
  );
};
