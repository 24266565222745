import React, { useState } from "react";
import "./textinput.css";

export const TextInput = ({
  title = "",
  type = "",
  value = "",
  error = "",
  ...inputProps
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <div className="relative input">
        <input
          {...inputProps}
          type={type === "password" && showPassword ? "text" : type}
          className={`input__field ${
            value && value.length > 0 ? "--filled" : ""
          }
                ${error && "--error"}`}
        />

        <label className="input__title">{title}</label>

        {type === "password" && (
          <svg
            className="input__icon"
            width={22}
            height={18}
            onClick={() => setShowPassword(!showPassword)}
          >
            <use xlinkHref="/uploads/icon-sprite.svg#password-eye" />
          </svg>
        )}
      </div>
      {error && (
        <div className="text-red pl-2 mt-1 text-sm text-left">{error}</div>
      )}
    </div>
  );
};
