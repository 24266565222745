import React from "react";
import { Icon } from "../../components/Icon/Icon";
import { ChildWrapper } from "../../components/layouts/ChildWrapper";

export const Footer = () => {
  return (
    <div className="bg-custom-black py-10">
      <ChildWrapper>
        <div className="flex gap-10 flex-wrap md:flex-row justify-between">
          <div></div>

          <div className="flex gap-3">
            <div className="bg-white p-3 rounded-full w-fit">
              <a
                href="https://www.facebook.com/rideonnigeria"
                target="_blank"
                rel="noreferrer"
              >
                <Icon svg="facebook" width={15} height={15} />
              </a>
            </div>
            <div className="bg-white p-3 rounded-full w-fit">
              <a
                href="https://www.instagram.com/rideonnigeria/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon svg="instagram" width={15} height={15} />
              </a>
            </div>
            <div className="bg-white p-3 rounded-full w-fit">
              <a
                href="https://twitter.com/rideonnigeria"
                target="_blank"
                rel="noreferrer"
              >
                <Icon svg="twitter" width={15} height={15} />
              </a>
            </div>
          </div>
        </div>
        <div className="text-sm text-white/75 mt-5">
          © 2020 Ride on Nigeria. All rights reserved.{" "}
        </div>
      </ChildWrapper>
    </div>
  );
};
