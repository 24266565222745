import * as yup from "yup";
import "yup-phone";

export const contactFormSchema = yup.object({
  FirstName: yup.string().required("Enter your first name"),
  LastName: yup.string().required("Enter your last name"),
  PhoneNo: yup
    .string()
    .phone("Enter a valid phone number")
    .required("Enter your phone number"),
  Email: yup.string().email("Enter a valid email").required("Enter your email"),
  TextInfo: yup.string().required("Enter your message"),
});
