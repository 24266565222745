import React, { useState } from "react";
import { Button } from "../button/Button";
import { Icon } from "../Icon/Icon";

export const Card = ({
  svg,
  title,
  subtext,
  className,
  hasButton,
  textIcon,
  iconRotate = true,
  svgClassName = "",
  dataAos = { undefined },
  AosDelay = { undefined },
  link,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`flex flex-col gap-5 justify-center tems-center px-6 md:px-9 py-6 md:py-10
        ${className} text-custom-black border border-custom-black
       hover:bg-custom-black hover:text-white
       cursor-pointer hover:scale-110 transition-all group rounded-lg shadow-sm
       backdrop-blur-md
       `}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-aos={dataAos}
      data-aos-delay={AosDelay}
    >
      {svg && (
        <Icon
          svg={svg}
          width={90}
          height={90}
          className={`${
            iconRotate &&
            "group-hover:border group-hover:border-white group-hover:rounded-full group-hover:border-dashed group-hover:p-3 transition-all"
          } text-secondary  bg-bg-gray rounded-full ${svgClassName} max-w-[70px] max-h-[70px] md:h-[90px] md:w-[90px]`}
        />
      )}
      {textIcon && (
        <div
          className={`${
            iconRotate &&
            "group-hover:animate-spin group-hover:border group-hover:border-white group-hover:rounded-full group-hover:border-dashed p-1"
          } 
        bg-custom-black text-center text-xl text:3xl w-[70px] h-[70px] md:h-[90px] md:w-[90px] rounded-full flex items-center justify-center font-black text-green
        `}
        >
          {textIcon}
        </div>
      )}
      {title && (
        <div className="text-xl lg:text-xl font-black calibre-sbold">
          {title}
        </div>
      )}
      <div className="max-w-[250px] calibre text-lg lg:text-lg mt-1 md:mt-4">
        {subtext}
      </div>
      {hasButton && (
        <a href={link} target="_blank" rel="noreferrer">
          <Button
            type="button"
            variation={hover ? "primary" : "black"}
            title="Book now"
            onClick={() => {
              return null;
            }}
            className="!border !py-3 !px-8 md:!py-2 md:!px-5 !text-base"
          />
        </a>
      )}
    </div>
  );
};
