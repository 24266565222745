import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { doContactUs } from "../../api/requests";
import { Button } from "../../components/button/Button";
import { ChildWrapper } from "../../components/layouts/ChildWrapper";
import { TextArea } from "../../components/TextInput/TextArea";
import { TextInput } from "../../components/TextInput/TextInput";
import { contactFormSchema } from "../../utils/validation";

export const Contact = () => {
  const [sendStatus, setSendStatus] = useState(true);
  const [statusType, setStatusType] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const changeStatus = setInterval(
      () => setSendStatus(false),
      3000 // every 3 seconds
    );
    return () => clearTimeout(changeStatus);
  }, [sendStatus]);

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    errors,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      PhoneNo: "",
      Email: "",
      TextInfo: "",
    },
    validationSchema: contactFormSchema,
    onSubmit: (body, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      doContactUs(body).then(({ success }) => {
        resetForm();
        if (success) {
          setSendStatus(true);
          setStatusType("success");
          setMessage("Your message has been sent successfully!");
        } else {
          setSendStatus(true);
          setStatusType("fail");
          setMessage("An error occured, please try again later!");
        }
      });
    },
  });

  return (
    <div className="bg-custom-black pt-20 text-black">
      <ChildWrapper>
        <div className="contact p-5 bg-white rounded-sm md:rounded-2xl w-full flex flex-wrap md:flex-row">
          <div className="w-full md:w-6/12 flex items-center justify-center text-center md:text-left">
            <div className="max-w-xs">
              <p className="font-black calibre-bold text-2xl md:text-5xl">
                Need to hire a Car or Driver?
              </p>
              <p className="w-9/12 m-auto text-center md:w-full">
                We’re just one click away, always available for you.
              </p>
            </div>
          </div>
          <div className="w-full md:w-6/12 flex items-center justify-center py-5">
            <form
              className=" md:p-5 rounded-lg w-full max-w-md flex flex-col gap-6"
              onSubmit={handleSubmit}
            >
              <TextInput
                title="First Name"
                type="text"
                value={values.FirstName}
                placeholder="First Name"
                name="FirstName"
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.FirstName && errors.FirstName && errors.FirstName
                }
              />
              <TextInput
                title="Last Name"
                type="text"
                value={values.LastName}
                placeholder="Last Name"
                name="LastName"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.LastName && errors.LastName && errors.LastName}
              />
              <TextInput
                title="Phone Number"
                type="text"
                value={values.PhoneNo}
                placeholder="Phone Number"
                name="PhoneNo"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.PhoneNo && errors.PhoneNo && errors.PhoneNo}
              />
              <TextInput
                title="Email"
                type="text"
                value={values.Email}
                placeholder="Email"
                name="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Email && errors.Email && errors.Email}
              />
              <TextArea
                title="Say something to us"
                type="text"
                value={values.TextInfo}
                placeholder="Say something to us"
                name="TextInfo"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.TextInfo && errors.TextInfo && errors.TextInfo}
              />
              <Button
                type="submit"
                variation="black-fill"
                title="Submit"
                onClick={() => {
                  return null;
                }}
                className="!w-full !justify-center"
                hasIcon={false}
                disabled={!isValid}
                loading={isSubmitting}
              />
              {sendStatus && (
                <div
                  className={`w-full text-center transition-all py-3 font-medium rounded-md
                  ${statusType === "success" && "text-green bg-green/20"}
                  ${statusType === "fail" && "text-red bg-red/20"}
                  `}
                >
                  {message}
                </div>
              )}
            </form>
          </div>
        </div>
      </ChildWrapper>
    </div>
  );
};
