import React from "react";

export const Header = ({
  preTitle = "",
  title,
  preTitleClass = "",
  showCircle = true,
}) => {
  return (
    <div className="tracking-[-2px] relative">
      {/* <div
        className={`rift tracking-[6px] text-lg lg:text-lg ${preTitleClass}`}
      >
        {preTitle}
      </div> */}
      <div className="text-4xl lg:text-[48px] calibre-bold font-black mt-3">
        {title}
      </div>
      {showCircle && (
        <div className="w-12 h-12 border border-transparent rounded-full bg-green/20 absolute top-[-20px] left-[-15px]"></div>
      )}
    </div>
  );
};
