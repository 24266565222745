import { client } from "./client";

export const getTestimonials = async () => {
  const resp = await client.get("feedback/Testimonials/get");
  if (resp.status === 200) {
    return resp.data;
  } else {
    return [];
  }
};

export const doContactUs = async (body) => {
  const resp = await client.post("feedback/contact/us", body);
  if (resp.status === 200 || resp.status === 201) {
    return { success: true, data: undefined };
  } else {
    return { success: false, data: undefined };
  }
};
