import React, { useState, useEffect } from "react";
import TextTransition, { presets } from "react-text-transition";
import { Button } from "../../components/button/Button";
import { Icon } from "../../components/Icon/Icon";

export const MainBanner = ({ goDown }) => {
  const texts = ["Luxury car", "Driver"];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="min-h-[600px] h-[60vh] lg:min-h-[98vh] flex relative">
      <div className="w-full  lg:w-8/12 overflow-visible relative bg-custom-black">
        <img
          src="./assets/images/land-cruiser4-removebg.png"
          alt="Mercedes SUV"
          className="hidden lg:block w-11/12 absolute -right-[48%] object-auto max-w-[1130px] max-w-[830px]d z-10 bottom-32"
        />
        <img
          src="./assets/images/thick-green-rect.svg"
          alt="thick-green-rect"
          className="hidden md:block absolute right-0 bottom-20 object-auto max-h-[610px] -z-0"
        />
        <div className="flex flex-col items-center lg:items-start justify-center h-full text-7xl md:text-8xl rift-bold">
          <div className="flex items-center">
            <Icon
              svg="rect-icon"
              width={40}
              height={8}
              className="hidden lg:block min-w-[120px]"
            />
            Hire a
          </div>
          <div className="h-fit flex items-center">
            <Icon
              svg="rect-icon"
              width={40}
              height={8}
              className="hidden lg:block text-green min-w-[120px]"
            />
            <TextTransition springConfig={presets.wobbly} className="relative">
              <div>{texts[index % texts.length]}</div>
              <div className="ani-border-bottom" />
            </TextTransition>
          </div>
          <div className="flex items-center">
            <Icon
              svg="rect-icon"
              width={40}
              height={8}
              className="hidden lg:block min-w-[120px]"
            />
            <div className="relative flex flex-col items-center gap-4 lg:block">
              <span>with ease</span>
              <a
                href="http://app.rideonnigeria.co/booking/Ride"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  type="button"
                  variation="primary"
                  title="Book now"
                  onClick={() => {
                    return null;
                  }}
                  className="lg:absolute top-[120%]"
                />
              </a>
              <img
                src="./assets/images/mercedes_suv.png"
                alt="Mercedes SUV"
                className="hidden "
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white w-4/12 relative hidden lg:block">
        <img
          src="./assets/images/thin-blue-rect.svg"
          alt="thin-blue-rect"
          className="absolute left-0 bottom-10 object-auto max-h-[600px] -z-0"
        />
      </div>

      <div className="absolute bottom-4 left-[45%] hover:animate-bounce cursor-pointer transition-all">
        <Icon
          svg="arrow-down"
          width={52}
          height={52}
          className=" min-w-[40px] lg:min-w-[100px] max-w-[10px]"
          onClick={() => goDown()}
        />
      </div>
    </div>
  );
};
