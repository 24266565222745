import React, { useState, useEffect } from "react";
import { baseURL } from "../../api/config";
import { getTestimonials } from "../../api/requests";
import { Header } from "../../components/header/Header";
import { Icon } from "../../components/Icon/Icon";
import { ChildWrapper } from "../../components/layouts/ChildWrapper";
import { DotProgress } from "../../components/progress/DotProgress";

export const Testimmonials = () => {
  const [index, setIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );

    getTestimonials().then((result) => {
      const tes = result.map(
        ({ TextInfo, FirstName, LastName, Location, Pic }) => {
          return {
            message: TextInfo,
            by: `${FirstName} ${LastName}`,
            location: Location,
            imgURL: `${baseURL}${Pic.slice(1)}`,
          };
        }
      );
      setTestimonials(tes);
    });

    return () => clearTimeout(intervalId);
  }, []);

  const goForward = () => {
    if (index < testimonials.length - 1) {
      setIndex(index + 1);
    }
  };

  const goBack = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  return (
    <ChildWrapper className="mt-20 md:mt-40">
      <div>
        <Header
          preTitle="Don’t hear it from us alone"
          title={
            <span className="text-black">
              Clients <br />
              <span className="text-secondary">Testimonials</span>
            </span>
          }
        />

        <div className="flex gap-8 items-center justify-center mt-10 md:mt-20 ">
          <div
            className="hidden md:block p-3 bg-secondary rounded-full cursor-pointer hover:border hover:scale-110 transition-all"
            onClick={goBack}
          >
            <Icon svg="left-caret" width={10} height={10} className="" />
          </div>

          <div
            className="w-full max-w-2xl rounded-lg md:rounded-3xl rounded-bl-none rounded-br-none
            bg-gradient-to-t from-white to-gray/20
            p-9 calibre text-custom-black
          "
          >
            <img
              src="./assets/images/big-quote.svg"
              alt="quote"
              className="max-w-[60px] md:max-w-[100px]"
            />
            <div className="text-lg md:text-xl mt-3">
              {testimonials[index % testimonials.length]?.message}
            </div>

            <div className="mt-14 flex flex-col md:flex-row gap-3 md:items-center">
              <div>
                <img
                  src={testimonials[index % testimonials.length]?.imgURL}
                  alt="profile"
                  className="w-[60px] h-[60px] md:w-[88px] md:h-[88px] rounded-full 
                  border-0 object-contain border-gray/20 p-2
                  bg-white
                  "
                />
              </div>
              <div>
                <div className="text-base font-black">
                  {testimonials[index % testimonials.length]?.by}
                </div>
                <div className="text-sm">
                  {testimonials[index % testimonials.length]?.location}
                </div>
              </div>
            </div>
          </div>

          <div
            className="hidden md:block p-3 bg-secondary rounded-full cursor-pointer hover:border hover:scale-110 transition-all"
            onClick={goForward}
          >
            <Icon svg="right-caret" width={10} height={10} className="" />
          </div>
        </div>

        <div className="flex justify-center mt-5 mb-16">
          <DotProgress
            progressLength={testimonials.length}
            active={index % testimonials.length}
          />
        </div>
      </div>
    </ChildWrapper>
  );
};
