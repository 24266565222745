import React, { useRef, useState, useEffect } from "react";
import { BodyWrapper } from "../components/layouts/BodyWrapper";
import { Navbar } from "../components/navbar/Navbar";
import { AboutUs } from "./components/AboutUs";
import { Banner2 } from "./components/Banner2";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { MainBanner } from "./components/MainBanner";
import { Services } from "./components/Services";
import { Stats } from "./components/Stats";
import { Testimmonials } from "./components/Testimonials";
import AOS from "aos";
import "aos/dist/aos.css";

export const Home = () => {
  const home = useRef();
  const about = useRef();
  const services = useRef();
  const contact = useRef();
  const learnMore = useRef();

  const [changeBg, setChangeBg] = useState(false);

  const changeBackground = () => {
    // console.log(window.scrollY);
    if (window.scrollY >= window.innerHeight - 100) {
      setChangeBg(true);
    } else {
      setChangeBg(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeBackground);
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 100,
    });
  }, []);

  const links = [
    {
      title: "Home",
      onClick: () => {
        home.current?.scrollIntoView({ behavior: "smooth" });
      },
    },
    {
      title: "About",
      onClick: () => {
        about.current?.scrollIntoView({ behavior: "smooth" });
      },
    },
    {
      title: "Services",
      onClick: () => {
        services.current?.scrollIntoView({ behavior: "smooth" });
      },
    },
    {
      title: "Contact",
      onClick: () => {
        contact.current?.scrollIntoView({ behavior: "smooth" });
      },
    },
  ];

  return (
    <BodyWrapper>
      <Navbar
        links={links}
        className={`transition-all fixed w-full py-5 ${
          changeBg && "bg-custom-black/90 backdrop-blur-md"
        }`}
        changeBg={changeBg}
      />

      <div ref={home}>
        <MainBanner goDown={links[1].onClick} />
      </div>

      <div ref={about}>
        <AboutUs
          onLearnMore={() => {
            learnMore.current?.scrollIntoView({ behavior: "smooth" });
          }}
        />
      </div>

      <div ref={services}>
        <Services />
      </div>

      <Banner2 />

      <div ref={learnMore}>
        <Stats />
      </div>

      <Testimmonials />

      <div ref={contact}>
        <Contact />
      </div>

      <Footer />
    </BodyWrapper>
  );
};
