import React, { useState } from "react";
import { Button } from "../../components/button/Button";
import { Header } from "../../components/header/Header";
import { ChildWrapper } from "../../components/layouts/ChildWrapper";

export const Banner2 = () => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`bg-custom-black h-screen md:max-h-[500px] flex items-center ${
        hover && "ani-image-grow"
      } py-6`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ChildWrapper className="flex flex-wrap justify-center gap-y-4">
        <div className="w-full md:w-6/12 ">
          <Header
            preTitle=""
            title={
              <span>
                Enjoy every moment
                <br />
                <span className="text-green">with us</span>
              </span>
            }
            showCircle={false}
          />
          <div className="max-w-md calibre text-lg md:text-2xl mt-5">
            At RideOnNigeria, we strive to give the very best in quality and
            overall experience.
          </div>

          <div className="flex gap-3 flex-wrap mt-5">
            <a
              href="http://wa.me/2348093341100"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                type="button"
                variation="white"
                title="Hire | Rent | Reserve a car"
                onClick={() => {
                  return null;
                }}
                hasIcon={false}
              />
            </a>
            <a
              href="http://wa.me/2348093341100"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                type="button"
                variation="white"
                title="Call: 08093341100"
                onClick={() => {
                  return null;
                }}
              />
            </a>
            <a
              href="http://wa.me/2348093341100"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                type="button"
                variation="green"
                title="Reach us on whatsapp"
                onClick={() => {
                  return null;
                }}
              />
            </a>
          </div>
        </div>

        <div className="w-full md:w-6/12 md:relative flex md:h-6">
          <img
            src="./assets/images/smiling_girl.png"
            alt="Mercedes SUV"
            className="object-contain w-full max-w-[400px] md:max-w-[580px] md:absolute lg:top-[-700%] md:right-[-15%]"
            data-aos="fade-down"
          />
        </div>
      </ChildWrapper>
    </div>
  );
};
