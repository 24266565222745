import React from "react";

export const DotProgress = ({ progressLength, active }) => {
  let circles = [];

  for (let i = 0; i < progressLength; i++) {
    circles.push(i);
  }

  return (
    <div className="flex gap-1">
      {circles.map((id) => {
        return (
          <div
            className={`w-[10px] h-[10px] rounded-full bg-transparent ${
              active === id && "bg-black scale-125 shadow-2xl"
            }
            border border-black transition-all
            `}
            key={id}
          ></div>
        );
      })}
    </div>
  );
};
