import React from "react";
import { Card } from "../../components/cards/Card";
import { Header } from "../../components/header/Header";
import { ChildWrapper } from "../../components/layouts/ChildWrapper";

export const Stats = () => {
  const stats = [
    {
      subtitle: "We are present in more than 7 cities across Nigeria.",
      icon: "",
      textIcon: "7+",
    },
    {
      subtitle: "We have over two hundred drivers across our service points.",
      icon: "",
      textIcon: "200+",
    },
    {
      subtitle:
        "We are accessible 24/7. Our channels are always open to attend to your needs.",
      icon: "",
      textIcon: "24/7",
    },
    {
      subtitle:
        "We offer cost effective quality services compared to competitors.",
      icon: "",
      textIcon: "₦",
    },
  ];
  return (
    <div className="">
      <ChildWrapper className="mt-40">
        <div className="flex flex-wrap md:flex-row justify-between items-center gap-4">
          <Header
            preTitle="Number one car rental service"
            title={
              <span className="text-green">
                Facts <br />
                <span className="text-custom-black">Speak Volume</span>
              </span>
            }
          />
          <div className="hidden md:flex flex-col items-center gap-2 justify-center text-custom-black font-black">
            <img
              src="/assets/images/award.svg"
              alt="award"
              className="max-w-[68px]"
            />
            <div>No. 1 Car Rental Service</div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 mt-10 md:mt-20 w-11/12">
          {stats.map(({ title, subtitle, icon, textIcon, svgClassName }, i) => {
            return (
              <Card
                svg={icon}
                textIcon={textIcon}
                title=""
                subtext={subtitle}
                hasButton={false}
                key={i}
                iconRotate={false}
                svgClassName={svgClassName}
              />
            );
          })}
        </div>
      </ChildWrapper>
    </div>
  );
};
