import React, { useState } from "react";
import { Button } from "../button/Button";
import { Icon } from "../Icon/Icon";
import { ChildWrapper } from "../layouts/ChildWrapper";

export const Navbar = ({ links = [], className = "", changeBg }) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className={`${className} z-50 ${showMenu && "bg-custom-black/90"}`}>
      <ChildWrapper>
        <div className="flex items-center justify-between transition-all">
          <div>
            <img
              src="./assets/images/logo.png"
              alt="thin-blue-rect"
              className="max-w-[50px] md:max-w-[100px]"
            />
          </div>

          <div className="hidden md:flex gap-7">
            {links.map((link, i) => {
              return (
                <div
                  onClick={link.onClick}
                  key={i}
                  className="text-xl md:text-lg cursor-pointer hover:font-black hover:scale-105 transition-all"
                >
                  {link.title}
                </div>
              );
            })}
          </div>

          <div>
            <a
              href="http://app.rideonnigeria.co/booking/Ride"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                type="button"
                variation={changeBg ? "white-outline" : "secondary"}
                title="Book now"
                onClick={() => null}
                className="hidden md:flex !text-lg"
              />
            </a>
          </div>

          <div
            className="md:hidden transition-all"
            onClick={() => setShowMenu(!showMenu)}
          >
            <Icon
              svg={showMenu ? "cancel" : "hamburger-menu"}
              width={showMenu ? 25 : 30}
              height={showMenu ? 25 : 30}
              className="md:hidden text-white transition-all"
            />
          </div>
        </div>
      </ChildWrapper>
      {showMenu && (
        <div className="flex flex-col pt-20 py-5 gap-7 rounded-b-lg transition-all">
          {links.map((link, i) => {
            return (
              <div
                onClick={() => {
                  link.onClick();
                  setShowMenu(false);
                }}
                key={i}
                className="text-xl text-center cursor-pointer hover:font-black hover:scale-105 transition-all"
              >
                {link.title}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
